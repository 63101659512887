export default {
  mixRecharge: ["Please fill in your email address"],
  language: "Language",
  common: ["Online service", "Unhover","Confirm","Cancel"],
  upload: ["Uploading...", "Wrong format", "Uploaded successfully", "Upload failed"],
  vanPull: ["No more data", "No data"],
  login: {
    text3: ['Change password', 'Please enter your registered email or phone to reset your password.'],
    text2: ['Mail', 'Verifica', 'Password', 'Invite', 'Phone'],
    text1: ['Log in', 'Welcome to web', "Email or phone", 'Password','Sign in'],
    text: ["Language","ZFC","Log in"],
    label: ["Logging in...", "Log in immediately"],
    placeholder: ["Please input your email", "Please enter your password"],
    default: ["No account?", "Sign up now","Forgot password?","Forgot Your Password"],
    codes:["account not exist","wrong password","account freezed","fail"]
  },
  register: {
    text: [
      "ZFC",
      "Verification code is being sent...",
      "Click to get",
      "Registering...",
      "Sign up now",
    ],
    placeholder: [
      "Please input your email",
      "Please enter verification code",
      "Please enter your password",
      "Please confirm your password",
      "Please enter the invitation code",
      "Different passwords twice",
      "Please enter verification code",
      "Invalid email"
    ],
    label: ["Already have account?{a} {line}", "Back to login"],
    codes:["Account exist","empty code","wrong code","password not the same","wrong recommend","fail"]
  },
  resetpwd:["Forgot password","Please input your email","Please enter verification code","Click to get","Please enter password","Reset Password"],
  footer: ["Front page", "Hall", "Order", "Mine"],
  home: {
    WebMarket:'WebMarket',
    special_attention:'Special attention',
    spot_market:'Spot_market',
    label:["Welcome"],
    broadcast: "Congratulations Member{member} Become{grade}",
    menu: ["Funding Journal", "Beginner's Tutorial", "Invitation Register", "Contact Customer Service"],
    noticeTitle: "Latest Announcement",
    msg: "Mission not open",
    video: "No video tutorials yet",
  },
  hall:{
    default:[
        "Trading Floor","Balance","I want to buy","I want to sell","Confirm sell",
      "Order List","Sell Order","Sell to merchant","Pending Order","Buy","Sell",
    ],
    list:[
        'Quantity','Unit Price','Not traded','Sell USDT','Please enter the amount of USDT','Get amount',"Amount",'Buy USDT','Amount spent'
    ],
    kbip:["Revoke","Excess amount","Must be within the amount"]
  },
  msglist:[
    "Message list"
  ],
  sell:{
      placeholder:["Please enter the selling price","Please enter the quantity to sell"],
      label:["balance(USDT)","Name","Bank Account","Current price(USDT)","best price to sell","selling price","sold quantity","best price","all","sure to sell"]
  },
  buy:{
    placeholder:["Please enter a buy price","Please enter the purchase quantity","Minimum amount","maximum amount"],
    label:["balance(USDT)","Name","Bank Account","Current price(USDT)","buy best price","buy price","Buy quantity","best price","all","sure buy","Amount"]
  },
  user: {
    default: [
      "Personal center",
      "Login account",
      "Invitation code",
      "Sign out",
      "Balance",
      "Gold",
      "Wallet",
    ],
    menu: [
      "My assets",
      "Team list",
      "Account binding",
      "Account details",
      "Platform Introduction",
      "Sign out",
    ],
  },
 bindAccount: {
    default: ["Bind account","Submit"],
    fields: ["Account Name", "Cellphone number", "Bank Account","name","Types","USDT Address"],
    placeholder: ["Please enter account name","Please enter phone number","Please enter Bank Account","Please enter bank name",
      "Please select USDT type","Please enter USDT address"],
  },
  wallet: {
    default: [
      "My assets",
      "Recharge",
      "Withdraw",
      "Recharge record",
      "Withdrawals record",
      "Recharged amount",
      "Amount sold",
      "Earned",
      "Account balance",
      "balance",
      "Recharge amount(USDT)",
      "Amount sold(USDT)",
      "Total revenue",
        "Upgrade business",
      "To upgrade the merchant, please contact customer service",
        "Confirm the settled business",
        "Merchant Certification",
      "You need pay","Payment voucher","Merchant deposit","Authenticate now","Nnformation","You need pay","Whether the balance is insufficient to recharge"
    ],
    label: [
      "Withdrawal method",
      "Withdrawal Amount",
      "Fund password",
      "Submit",
      "Phone number",
      "Mail",
      "IFSC",
      "Sure",
    ],
    placeholder: [
      "Choose a withdrawal method",
      "Please enter the withdrawal amount",
      "Please enter the fund password",
      "Please select a withdrawal method",
      "Please enter the recipient's mobile number",
      "Please enter recipient email",
      "Please enter payee IFSC",
    ],
    msg: [
      "You have not set up a fund password, please set it first",
      "You have not bound your PIX account, please bind it first",
      "Sure you want to withdraw?",
    ],
  },
  recharge: {
    default: [
      "Wallet recharge",
      "Wallet pre-charge",
      "Recharge method",
        "Recharge",
        "Confirm",
    ],
    label: [
      "Category",
      "Address",
      "Recharge amount",
      "Enter amount",
      "Upload certificate",
        "Copy successfully",
        "Precharge Exchange"
    ],
    placeholder: [
      "Types",
      "Address",
    ],
    info: [
      "Recharge amount",
      "Order number",
      "Beneficiary Bank",
      "Receiving account",
      "Payee",
      "Copy",
    ]
  },
  task: {
    tabs: ["All","In progress", "Expired", "Completed","Wait pay","Wait confirm"],
    default: ["Order List",],
    msg: ["Submit review", "Submitted, please wait for review", "Failed to submit, please resubmit"],
  },
  userInfo: {
    default: [
      "Personal information",
      "Avatar",
      "Account",
      "Mail",
      "Alipay",
      "Details",
      "Change Password",
      "Fund password",
      "Click on settings",
      "Modify avatar",
      "Modify login password",
      "Modify the fund password",
      "Submit",
      "Empty the cache",
      "View information",
      "Nick name",
      "Referral code",
       "Credit"
    ],
    label: [
      "Original login password",
      "New login password",
      "Confirm password",
      "Original fund password",
      "New fund password",
      "Confirm password",
      "Save"
    ],
    placeholder: [
      "Please enter the original login password",
      "Please enter a new login password",
      "Please confirm the login password",
      "Please enter the original fund password",
      "Please enter new fund password",
      "Please confirm the fund password",
    ],
  },
  fundRecord: {
    default: ["Expense record", "Recharge record", "Asset log", "Charge", "Receive", "Branch"],
    tabs: ["Income", "Expenditure", "Recharge"],
  },
 dialog: [
    "Hint",
    "Confirm",
    "Submitting...",
    "Copy successfully",
    "IOS system version is not supported",
    "Registering...",
    "Loading...",
  ],
  serviceCenter: [
    "Customer Service",
    "Hi,I am a dedicated customer service~",
    "Glad to serve you",
    "Self service",
    "Online service",
    "Recharge customer service",
    "Line customer service",
  ],
 userTaskRecord: [
    "My Order",
    "Sell order",
    "Buy order",
    "Current state",
    "Get commission",
    "Completed",
  ],
  withdrawlist: [
    "Withdrawals record",
  ],
  teamReport: {
    default: [
      "Team list",
    ],
     team_size: 'Team size',
     new_this_month:'New this month',
     invite_friend:'Invite friend',
  },
  common2:['Beginner\'s Tutorial',"Contact Customer Service","Terms of Service, see details","Customer service 1","Customer service 2"],
  common3:['Success',"Platform Introduction"],
  invite:['Share promotion','My invitation code',"Copy the invitation code to invite more friends to join","copy","Could not invite now!"],
  common4:['Real name authentication',"Submitted successfully","matters needing attention","Content of matters","premium received","Front of ID card","Financial certificate"],
  common5:["Sold","Minutes","before","Save QR code","Open","The account is frozen and cannot be traded for the time being","APP"],
  common6:["RechargeOrder","OrderNumber","Amount","Time","Status"],
  hall2:["min sell amount is","","exceed daily sell count"],
  register2:["Mail","Phone","Please enter phone number"],
  withdraw2:["Withdraw","SellerAccount","Balance","Need pay","Pay","There are still tasks that have not been completed. Cash withdrawal is not allowed for the time being","Withdrawal count exceed"],
  mytask2:["MerchantAccount","price","amount","balance","pay amount","match time",
      "OrderDetail","number can't be 0","balance not enough","price can't 0","wrong scope"],
  taskOrder2:["You still have an open order and can't be cancelled","balance not enough","Confirm","fail","You still need to complete the order before you can withdraw cash:"],
  busAuth2:['MerchantUp','I agree',"up confirm","up Merchant",
      "If you need to modify the bank card information, please contact customer service",
  "The current account needs to be upgraded to a merchant account"],
    errauth:['Account violation',"You have violated the regulations and need to pay:","Your account have violated"],
    hall3:["Minutes","Hours","Days","second","GroupOrder","account",'overtime'],
    buy3:["count down","hour","type",'WelfareOrder','GroupOrder','count limit','count limit','specify Userid','input user id'],
    sell3:['sell price can not bigger than best price'],
    home4:["Quick trade","Quick buy USDT","C2C trade","TransferUSDT",'Online',"24H Amount(USDT)"],
};
