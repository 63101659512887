<template>
  <van-tabbar
    v-model="tabbarIndex"
    @click="activeTabbar()"
    safe-area-inset-bottom
    :border="false"
    inactive-color="#626262"
    active-color="#55D3E4"
    z-index="99"
    :fixed="true"
  >
    <van-tabbar-item to="/" name="home" class="home">
      <img
        style="width: 26px; height: 26px"
        slot="icon"
        slot-scope="props"
        :src="props.active ? icon_nav1.active : icon_nav1.normal"
        size="30"
      />
      {{ $t("footer[0]") }}
    </van-tabbar-item>
    <van-tabbar-item name="hall" class="task" @click="clickHall">
      <img
              style="width: 26px; height: 26px"
              slot="icon"
              slot-scope="props"
              :src="props.active ? icon_nav2.active : icon_nav2.normal"
      />
      {{ $t("footer[1]") }}
    </van-tabbar-item>
    <van-tabbar-item to="/myTask" name="myTask" class="task">
      <img
        style="width: 26px; height: 26px"
        slot="icon"
        slot-scope="props"
        :src="props.active ? icon_nav3.active : icon_nav3.normal"
      />
      {{ $t("footer[2]") }}
    </van-tabbar-item>
     <van-tabbar-item to="/user" name="user" class="user">
      <img
        style="width: 26px; height: 26px"
        slot="icon"
        slot-scope="props"
        :src="props.active ? icon_nav4.active : icon_nav4.normal"
      />
      {{ $t("footer[3]") }}
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import icon1 from '@/assets/img/new/foot/icon1.png'
import icon1_a from '@/assets/img/new/foot/icon1_a.png'
import icon2 from '@/assets/img/new/foot/icon2.png'
import icon2_a from '@/assets/img/new/foot/icon2_a.png'
import icon3 from '@/assets/img/new/foot/icon3.png'
import icon3_a from '@/assets/img/new/foot/icon3_a.png'
import icon4 from '@/assets/img/new/foot/icon4.png'
import icon4_a from '@/assets/img/new/foot/icon4_a.png'
export default {
  name: "Footer",
  components: {},
  props: [],
  data() {
    return {
      tabbarIndex: this.$route.matched[0].meta.active,
      icon_nav1: {
        normal: icon1,
        active: icon1_a,
      },
      icon_nav2: {
        normal: icon2,
        active: icon2_a,
      },
      icon_nav3: {
        normal: icon3,
        active: icon3_a,
      },
      icon_nav4: {
        normal: icon4,
        active: icon4_a,
      },
      icon_nav5: {
        normal: "./static/icon/user.png",
        active: "./static/icon/user_select.png",
      },
      icon_nav6: {
        normal: "./static/icon/profit.png",
        active: "./static/icon/profit_select.png",
      },
      icon_nav7: {
        normal: "./static/icon/activity.png",
        active: "./static/icon/activity_select.png",
      },
    };
  },
  computed: {},
  watch: {
    tabbarIndex(val) {
      console.log(this.tabbarIndex);
    },
  },
  created() {},
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    activeTabbar(index) {
      console.log(this.tabbarIndex);
    },
    clickHall(){
      let info = this.UserInfo;
      if (info.bus_deposit>0&&info.bus_amount>0){
        this.$router.push("/busauth");
        return;
      }
      this.$router.push("/hall");
    }
  },
};
</script>
<style scoped>
  .van-tabbar {
    height: 62px;
    background-color: #fff;
    /*border-radius:20px;*/
    /*border:2px solid #DCDCDC;*/
    /*box-shadow: 3px 3px 0px 0px #888;*/
    /*background: #0e1526;*/
    box-shadow: 0px 0px 10px 0px rgba(148, 148, 148, 0.31);
    border-radius: 1rem 1rem 0 0;
    overflow: hidden;
  }

  .van-tabbar-item__icon {
    font-size: 24px;
  }

  .van-tabbar-item__icon img {
    height: 24px;
  }

  .van-tabbar-item__icon .addTask {
    height: 72px;
    margin: -38px 0 -10px;
  }

  .van-tabbar-item--active {
    background: #FFF;
    -webkit-background-clip: text;
  }
  .van-tabbar-item--active >>> .van-tabbar-item__text {
    background: #55D3E4;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .van-tabbar-item >>> .van-tabbar-item__text {
    font-size: 13px !important;
    font-family: Alibaba !important;
    margin-top: 2px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .van-tabbar >>> .van-tabbar-item {
    width: 20%;
  }
</style>

